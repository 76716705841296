import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import styles from './summary.styles';
import profileImgPlaceholder from '../../assets/no-profile-picture.png';
import plexiLogo from '../../assets/logo_blue.png';
import { trackReadyToPay } from '../../amplitude';

const Summary = ({
  linkData,
  price,
  shippingPrice,
  profileImage,
  businessName,
}) => {
  // pre-render data
  const IMAGE_STORAGE_URL =
    'https://plexi-app-images155849-dev.s3.amazonaws.com/public/';
  const navigate = useNavigate();
  const params = useParams();
  const { urlToken } = params;

  const navigateOnClick = () => {
    trackReadyToPay({ orderLink: urlToken });
    navigate(`/${urlToken}/address`);
  };

  return (
    <div style={styles.background}>
      {linkData?.linkItems && (
        <div style={styles.container}>
          <div style={styles.boxContainer}>
            <img
              src={
                profileImage
                  ? IMAGE_STORAGE_URL + profileImage
                  : profileImgPlaceholder
              }
              alt="profile"
              style={styles.profileImg}
            />
            <div style={styles.profileImgBackground} />
            <div style={styles.profileImgBackground2} />
            <div style={styles.priceHeader}>
              <Typography sx={styles.businessNameText}>
                {businessName}
              </Typography>
              <Typography sx={[styles.greyText, { marginTop: 2 }]}>
                Item Price
              </Typography>
              <Typography style={styles.priceHeaderText}>{`$${price?.toFixed(
                2
              )}`}</Typography>
            </div>
            <div style={styles.itemContainer}>
              {linkData &&
                linkData.linkItems.map((item) => {
                  return (
                    <div key={item.id} style={styles.item}>
                      {item.imageUrl != null && (
                        <img
                          src={IMAGE_STORAGE_URL + item.imageUrl}
                          alt="item"
                          style={styles.itemImage}
                        />
                      )}
                      <div style={styles.itemTextContainer}>
                        <Typography align="left" style={styles.greyText}>
                          Item
                        </Typography>
                        <Typography
                          align="left"
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            marginTop: 8,
                          }}
                        >
                          {item.itemName}
                          {item.quantity > 1 && ` x ${item.quantity}`}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
            </div>
            {price && (
              <div>
                <div style={styles.divider} />
                <Grid container rowSpacing={2}>
                  <Grid item xs={6}>
                    <Typography style={styles.greyText}>Item Prices</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right" style={{ fontSize: 14 }}>
                      {`$${price?.toFixed(2)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography display="inline" style={styles.greyText}>
                      Shipping
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <Typography display="inline" style={{ fontSize: 14 }}>
                      {shippingPrice ? `$${shippingPrice?.toFixed(2)}` : '--'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      display="inline"
                      style={{ fontWeight: 'bold', fontSize: 13 }}
                    >
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <Typography
                      display="inline"
                      style={{
                        fontWeight: 'bold',
                        color: '#1251D4',
                        fontSize: 14,
                      }}
                    >
                      {shippingPrice
                        ? `$${(price + shippingPrice)?.toFixed(2)}`
                        : `--`}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
            {linkData && (
              <Button style={styles.nextButton} onClick={navigateOnClick}>
                <Typography style={{ fontSize: 16 }}>Ready to pay</Typography>
              </Button>
            )}
          </div>
        </div>
      )}
      <div style={styles.disclaimerContainer}>
        <Typography style={styles.disclaimerText}>Powered by</Typography>
        <img src={plexiLogo} alt="logo" style={styles.plexiLogo} />
      </div>
      {linkData === 'error' && (
        <div style={styles.container}>
          <div style={styles.errorContainer}>
            <LinkOffIcon style={styles.errorIcon} />
            <Typography style={styles.errorText}>
              Sorry, this link is unavailable
            </Typography>
          </div>
        </div>
      )}
      {linkData?.status === 'DISABLED' && (
        <div style={styles.container}>
          <div style={styles.errorContainer}>
            <LinkOffIcon style={styles.errorIcon} />
            <Typography style={styles.errorText}>
              This link is no longer active
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default Summary;
