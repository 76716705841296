export const styles = {
  container: {
    maxWidth: 400,
    margin: '0 auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 32,
    paddingLeft: 32,
  },
  logo: {
    height: 45,
    marginTop: 5,
    marginBottom: 5,
  },
  infoContainer: {
    borderRadius: 16,
    backgroundColor: '#F2F2F2',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 18,
    marginBottom: 48,
  },
  divider: {
    borderTop: '1.2px dashed rgba(29, 27, 35, .08)',
    width: '100%',
    marginBottom: 20,
  },
  addressHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 48,
    marginBottom: 8,
    alignSelf: 'flex-start',
  },
  nextButton: {
    marginTop: 80,
    marginBottom: 48,
    color: 'white',
    borderRadius: 50,
    textTransform: 'none',
    width: 250,
    padding: 12,
  },
  greyText: {
    color: '#9FA2AB',
    fontSize: 13,
  },
  errorMessage: {
    fontSize: 13,
    color: 'red',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 200,
  },
};

export default styles;
