import { AWSAppSyncClient } from 'aws-appsync';
import AppSync from '../AppSync';

export default new AWSAppSyncClient({
  disableOffline: true,
  url: AppSync.aws_appsync_graphqlEndpoint,
  region: AppSync.aws_appsync_region,
  auth: {
    type: AppSync.aws_appsync_authenticationType,
    apiKey: AppSync.aws_appsync_apiKey,
  },
});
