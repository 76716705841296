import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { OrderForm, UserStore } from './pages';

const App = () => {
  return (
    <Routes>
      <Route path="/:urlToken/*" element={<OrderForm />} />
      <Route path="/user/:userId/*" element={<UserStore />} />
    </Routes>
  );
};

export default App;
