import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { debounce } from 'debounce'; // to avoid button double clicks
import blueLogo from '../../assets/logo_blue.png';
import { statesData } from '../../constants/states';
import { createOrder } from '../../database/queries';
import {
  trackEnteredName,
  trackEnteredEmail,
  trackClickedNextPayment,
} from '../../amplitude';

import styles from './address.styles';

const Address = ({
  email,
  setEmail,
  price,
  shippingPrice,
  linkData,
  fullName,
  setFullName,
  address1,
  setAddress1,
  address2,
  setAddress2,
  city,
  setCity,
  state,
  setState,
  zipcode,
  setZipcode,
  requireShipping,
  setOrderId,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const { urlToken } = params;
  const items = linkData?.linkItems;
  const linkId = linkData?.id;
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);

  const emailRegex = /^\S+@\S+\.\S+$/;
  emailRegex.test(email);

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleAddress1Change = (e) => {
    setAddress1(e.target.value);
  };
  const handleAddress2Change = (e) => {
    setAddress2(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
  };
  const handleZipcodeChange = (e) => {
    setZipcode(e.target.value);
  };

  const onClick = debounce(
    async () => {
      if (linkData?.status === 'DISABLED') {
        // eslint-disable-next-line no-alert
        alert('This link is currently unavailable');
        return;
      }

      if (!emailRegex.test(email)) {
        setEmailErrorMessage(true);
      } else {
        const input = {
          email,
          name: fullName,
          address1,
          address2,
          city,
          state,
          zipcode,
          price,
          status: 'WAITING_FOR_PAYMENT',
        };

        trackClickedNextPayment({
          orderLink: urlToken,
          fullName,
          email,
          address1,
          address2,
          city,
          state,
          zipcode,
          price,
        });

        // TODO: SHIPPO ADDRESS VALIDATION https://goshippo.com/docs/address-validation/
        // Make API call to shippo to check address
        // If true, then run code below
        const res = await createOrder(linkId, input);
        const orderId = res.data.createOrder.id;
        setOrderId(orderId);

        navigate(`/${urlToken}/payment`);
      }
    },
    500,
    true
  );

  return (
    <div style={styles.container}>
      {linkData?.status === 'DISABLED' ? (
        <div style={styles.errorContainer}>
          <LinkOffIcon style={styles.errorIcon} />
          <Typography style={styles.errorText}>
            This link is no longer active
          </Typography>
        </div>
      ) : (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={blueLogo} alt="logo" style={styles.logo} />
          <div style={styles.infoContainer}>
            <Typography
              align="left"
              style={{ color: '#9FA2AB', fontSize: 13, marginBottom: 8 }}
            >
              Item
            </Typography>
            {items &&
              items.map((item) => {
                return (
                  <Typography
                    key={item.id}
                    align="left"
                    style={{ fontSize: 14, marginBottom: 16 }}
                  >
                    {item.itemName}
                    {item.quantity > 1 && ` x ${item.quantity}`}
                  </Typography>
                );
              })}
            <div style={styles.divider} />
            <Grid container rowSpacing={2}>
              <Grid item xs={6}>
                <Typography style={styles.greyText}>Item Prices</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" style={{ fontSize: 14 }}>
                  {`$${price?.toFixed(2)}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography display="inline" style={styles.greyText}>
                  Shipping
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography display="inline" style={{ fontSize: 14 }}>
                  {shippingPrice ? `$${shippingPrice?.toFixed(2)}` : '--'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  display="inline"
                  style={{ fontWeight: 'bold', fontSize: 13 }}
                >
                  Total
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography
                  display="inline"
                  style={{ fontWeight: 'bold', color: '#1251D4', fontSize: 14 }}
                >
                  {shippingPrice
                    ? `$${(price + shippingPrice)?.toFixed(2)}`
                    : `--`}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <TextField
            required
            size="small"
            sx={{ width: '95%', input: { color: 'black' } }}
            id="full-name"
            label="Full name"
            variant="standard"
            onChange={handleNameChange}
            onBlur={() => trackEnteredName({ fullName, orderLink: urlToken })}
            defaultValue={fullName}
          />
          <TextField
            required
            size="small"
            sx={{ width: '95%', input: { color: 'black' } }}
            id="email"
            label="Email"
            variant="standard"
            onChange={handleEmailChange}
            onBlur={() => trackEnteredEmail({ email, orderLink: urlToken })}
            defaultValue={email}
          />
          {emailErrorMessage && (
            <Typography style={styles.errorMessage}>
              Please enter a valid email address
            </Typography>
          )}
          {requireShipping && (
            <div>
              <Typography style={styles.addressHeader}>
                Shipping Address
              </Typography>
              <TextField
                required
                size="small"
                sx={{ width: '95%', input: { color: 'black' } }}
                id="address"
                label="Address 1"
                variant="standard"
                onChange={handleAddress1Change}
                defaultValue={address1}
              />
              <TextField
                size="small"
                sx={{ width: '95%', input: { color: 'black' } }}
                id="address2"
                label="Address 2"
                variant="standard"
                onChange={handleAddress2Change}
                defaultValue={address2}
              />
              <TextField
                required
                size="small"
                sx={{ width: '95%', input: { color: 'black' } }}
                id="city"
                label="City"
                variant="standard"
                onChange={handleCityChange}
                defaultValue={city}
              />
              <Grid
                container
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  width: '95%',
                }}
              >
                <FormControl
                  label="State"
                  id="state"
                  variant="standard"
                  sx={{ padding: 0, width: '47%', input: { color: 'black' } }}
                >
                  <InputLabel id="state" label="State" variant="standard">
                    State
                  </InputLabel>
                  <Select
                    id="state"
                    label="State"
                    variant="standard"
                    value={state}
                    onChange={handleStateChange}
                  >
                    {statesData.map((value) => {
                      return (
                        <MenuItem value={value} key={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  required
                  size="small"
                  label="Zipcode"
                  sx={{ width: '47%', input: { color: 'black' } }}
                  variant="standard"
                  onChange={handleZipcodeChange}
                  defaultValue={zipcode}
                />
              </Grid>
            </div>
          )}

          <Button
            onClick={onClick}
            style={styles.nextButton}
            disabled={
              fullName.length === 0 ||
              email.length === 0 ||
              (requireShipping && address1.length === 0) ||
              (requireShipping && city.length === 0) ||
              (requireShipping && state.length === 0) ||
              (requireShipping && zipcode.length === 0)
            }
          >
            <Typography style={{ fontSize: 16 }}>Next: Payment</Typography>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Address;
