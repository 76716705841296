export const styles = {
  container: {
    maxWidth: 400,
    margin: '0 auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 32,
    paddingLeft: 32,
  },
  logo: {
    height: 45,
    marginTop: 5,
    marginBottom: 5,
  },
  infoContainer: {
    borderRadius: 16,
    backgroundColor: '#F2F2F2',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 18,
    marginBottom: 20,
  },
  textInputContainer: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  paymentHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    marginTop: 32,
  },
  paymentSubHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: 32,
    marginBottom: 16,
  },
  cardIcon: {
    height: 24,
    width: 24,
    marginRight: 16,
  },
  divider: {
    borderTop: '1.2px dashed rgba(29, 27, 35, .08)',
    width: '100%',
    marginBottom: 20,
  },
  disclaimerText: {
    fontSize: 12,
    color: '#4F4F4F',
  },
  nextButton: {
    alignSelf: 'center',
    marginTop: 45,
    color: 'white',
    borderRadius: 50,
    textTransform: 'none',
    width: 250,
    padding: 12,
    marginBottom: 43,
  },
  stripeLogo: {
    height: 20,
    verticalAlign: 'middle',
  },
  greyText: {
    color: '#9FA2AB',
    fontSize: 13,
  },
};

export default styles;
