import receipt from '../../assets/receipt.png';

export const styles = {
  background: {
    background: 'linear-gradient(#E0DFED 20%, rgba(255, 255, 255, 0.15) 100%',
  },
  container: {
    width: 360,
    height: 668,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${receipt})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  profileImg: {
    height: 80,
    width: 80,
    position: 'absolute',
    top: 42,
    borderRadius: 90,
    alignSelf: 'center',
    zIndex: 1,
  },
  profileImgBackground: {
    backgroundColor: '#1251D4',
    opacity: '.07',
    height: 90,
    width: 90,
    position: 'absolute',
    top: 37,
    borderRadius: 45,
    alignSelf: 'center',
  },
  profileImgBackground2: {
    backgroundColor: '#FFFFFF',
    height: 84,
    width: 84,
    position: 'absolute',
    top: 40,
    borderRadius: 45,
    alignSelf: 'center',
  },
  priceHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 12,
  },
  businessNameText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000000',
    marginTop: 4,
  },
  priceHeaderText: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  errorIcon: {
    height: 24,
    width: 24,
    justifyContent: 'center',
    display: 'flex',
  },
  errorText: {
    fontSize: 16,
    marginLeft: 12,
    justifyContent: 'center',
    display: 'flex',
  },
  boxContainer: {
    marginTop: 100,
    width: 260,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  itemContainer: {
    width: 280,
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    scrollbars: 'false',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
  },
  itemImage: {
    height: 80,
    width: 80,
    borderRadius: 8,
    marginRight: 16,
    objectFit: 'cover',
  },
  divider: {
    borderTop: '1.2px dashed rgba(29, 27, 35, .08)',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  nextButton: {
    alignSelf: 'center',
    marginTop: 20,
    color: 'white',
    borderRadius: 50,
    textTransform: 'none',
    width: 200,
    padding: 12,
    marginBottom: 24,
  },
  greyText: {
    color: '#9FA2AB',
    fontSize: 13,
  },
  disclaimerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -60,
  },
  disclaimerText: {
    marginTop: 2,
    fontSize: 13,
    color: '#4F4F4F',
  },
  plexiLogo: {
    marginLeft: 6,
    height: 25,
  },
};

export default styles;
