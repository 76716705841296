import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './user-store.styles';
import profileImgPlaceholder from '../../assets/no-profile-picture.png';
import blueLogo from '../../assets/logo_blue.png';
import placeholder from '../../assets/image.png';

const UserStore = ({ profileImage }) => {
  const navigate = useNavigate();
  const IMAGE_STORAGE_URL =
    'https://plexi-app-images155849-dev.s3.amazonaws.com/public/';
  const [userName] = useState('ShoeBoss');
  const [availableItems] = useState([
    {
      name: 'Vintage brown bear red sweater vest',
      price: 100,
      imageUrl: 'https://i.ebayimg.com/images/g/Eh4AAOSwPPNbeHwl/s-l400.jpg',
      urlToken: 'cyvjnEzxX1LpcRWHzbi23h',
    },
    {
      name: 'smiling vintage bear sweater',
      price: 100,
      urlToken: 'cyvjnEzxX1LpcRWHzbi23h',
    },
    {
      name: 'Levi’s 517 jeans. Dark wash high rise',
      price: 100,
      urlToken: 'cyvjnEzxX1LpcRWHzbi23h',
    },
    {
      name: 'Vintage crochet boho purse',
      price: 100,
      urlToken: 'cyvjnEzxX1LpcRWHzbi23h',
    },
  ]);

  const navigateOnClick = (urlToken) => {
    navigate(`/${urlToken}`);
  };

  useEffect(() => {
    // TODO: INSERT QUERY TO GET USER INFORMATION HERE
  });

  return (
    <div style={styles.background}>
      <img
        src={
          profileImage
            ? IMAGE_STORAGE_URL + profileImage
            : profileImgPlaceholder
        }
        alt="profile"
        style={styles.profileImg}
      />

      <div style={styles.profileImgBackground} />
      <div style={styles.header}>
        <Typography style={{ fontWeight: '600' }}>{userName}</Typography>
      </div>
      <div style={styles.body}>
        <Typography
          style={{
            marginTop: 20,
            marginBottom: 16,
            fontWeight: 500,
            fontSize: 13,
          }}
        >
          Available items ({availableItems.length})
        </Typography>
        {availableItems.map((item) => {
          // @FIXME: Wrapper <div> needs restructuring to be a11y compliant
          /* eslint-disable jsx-a11y/click-events-have-key-events */
          /* eslint-disable jsx-a11y/no-static-element-interactions */
          return (
            <div
              style={styles.itemContainer}
              onClick={() => navigateOnClick(item.urlToken)}
            >
              {item.imageUrl != null ? (
                <div>
                  <img
                    src={item.imageUrl}
                    // src={IMAGE_STORAGE_URL + item.imageUrl}
                    alt="item"
                    style={styles.itemImage}
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={placeholder}
                    // src={IMAGE_STORAGE_URL + item.imageUrl}
                    alt="item"
                    style={styles.itemImage}
                  />
                </div>
              )}
              <div style={styles.itemTextContainer}>
                <Typography
                  align="left"
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  align="left"
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: 13,
                  }}
                >
                  {`$${item.price}`}
                </Typography>
              </div>
              <ChevronRightIcon
                style={{
                  marginRight: 10,
                }}
              />
            </div>
          );
        })}
      </div>
      <img src={blueLogo} alt="logo" style={styles.logo} />
    </div>
  );
};
export default UserStore;
