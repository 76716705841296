import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography, CircularProgress } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import styles from './payment.styles';
import blueLogo from '../../assets/logo_blue.png';
import stripeLogo from '../../assets/stripe_logo.png';
import { CheckoutForm } from '../../components';
import { SERVER_URL, STRIPE_KEY } from '../../constants/config';

const stripePromise = loadStripe(STRIPE_KEY);

const Payment = ({
  price,
  linkData,
  clientSecret,
  setClientSecret,
  shippingPrice,
  setShippingPrice,
  requireShipping,
  fullName,
  address1,
  address2,
  city,
  state,
  email,
  zipcode,
  orderId,
  sellerEmail,
}) => {
  const items = linkData?.linkItems;
  const params = useParams();

  const { urlToken } = params;
  const navigate = useNavigate();

  const appearance = {
    theme: 'flat',
    labels: 'floating',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#F2F4F5',
      colorText: '#30313d',
      spacingUnit: '2px',
      fontSizeBase: '14px',
      borderRadius: '0px',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    fetch(`${SERVER_URL}/api/create_payment`, {
      method: 'POST',
      headers: {
        'access-control-allow-origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        total_amount: (shippingPrice ? price + shippingPrice : price) * 100, // total_amount in cents
        order_id: orderId,
        required_shipping: requireShipping.toString(),
        name: fullName,
        address_first: address1,
        address_second: address2,
        city,
        state,
        zipcode,
        buyer_email: email,
        seller_email: sellerEmail,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.ok) {
          // TODO: paying for inactive link case handling.
          // eslint-disable-next-line no-alert
          alert(
            'Address is invalid. Please retry. Going back to the previous page'
          );
          setTimeout(() => {
            navigate(`/${urlToken}/address`);
          }, 500);
        }
        setClientSecret(data.client_secret);
        if (data.rate && !shippingPrice) {
          setShippingPrice(Number.parseFloat(data.rate / 100));
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={styles.container}>
      <img src={blueLogo} alt="logo" style={styles.logo} />
      <div style={styles.infoContainer}>
        <Typography
          align="left"
          style={{ color: '#9FA2AB', fontSize: 13, marginBottom: 8 }}
        >
          Item
        </Typography>
        {items &&
          items.map((item) => {
            return (
              <Typography
                key={item.id}
                align="left"
                style={{ fontSize: 14, marginBottom: 16 }}
              >
                {item.itemName}
                {item.quantity > 1 && ` x ${item.quantity}`}
              </Typography>
            );
          })}
        <div style={styles.divider} />
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <Typography style={styles.greyText}>Item Prices</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right" style={{ fontSize: 14 }}>
              {`$${price?.toFixed(2)}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography display="inline" style={styles.greyText}>
              Shipping
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography display="inline" style={{ fontSize: 14 }}>
              {shippingPrice ? `$${shippingPrice?.toFixed(2)}` : '--'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              display="inline"
              style={{ fontWeight: 'bold', fontSize: 13 }}
            >
              Total
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography
              display="inline"
              style={{ fontWeight: 'bold', color: '#1251D4', fontSize: 14 }}
            >
              {shippingPrice
                ? `$${(price + shippingPrice)?.toFixed(2)}`
                : `$${price?.toFixed(2)}`}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Typography style={styles.paymentHeader}>Payment</Typography>
      <div style={styles.paymentSubHeader}>
        <CreditCardIcon style={styles.cardIcon} />
        <Typography style={{ fontSize: 14, fontWeight: '400' }}>
          Add Credit / Debit Card
        </Typography>
      </div>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            shippingPrice={shippingPrice}
            price={price}
            email={email}
            orderId={orderId}
          />
        </Elements>
      ) : (
        <CircularProgress color="inherit" style={{ marginBottom: 15 }} />
      )}
      <Typography style={styles.disclaimerText}>
        We never store your payment information directly.
      </Typography>
      <Typography style={styles.disclaimerText}>
        Our payment solution is powered by
        <img src={stripeLogo} alt="logo" style={styles.stripeLogo} />
      </Typography>
    </div>
  );
};

export default Payment;
