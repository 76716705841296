import { identify, track, events } from './Analytics';

/**
 * Buyer Orderform tracking
 * will leave userId as a parameter for later when we build buyer Auth as well
 *
 */

export const trackReadyToPay = ({ userId, email, orderLink }) => {
  const trackingOpts = {
    id: userId || 'noUserId',
    usernameOrEmail: email || 'noEmail',
    orderLink,
  };
  identify(userId, trackingOpts);
  track(events.BUYER_CLICKED_READY_TO_PAY, trackingOpts);
};

export const trackEnteredName = ({ userId, fullName, orderLink }) => {
  const trackingOpts = {
    id: userId || 'noUserId',
    fullName,
    orderLink,
  };
  identify(userId, trackingOpts);
  track(events.BUYER_ENTERED_NAME, trackingOpts);
};

export const trackEnteredEmail = ({ userId, email, orderLink }) => {
  const trackingOpts = {
    id: userId || 'noUserId',
    usernameOrEmail: email || 'noEmail',
    orderLink,
  };
  identify(userId, trackingOpts);
  track(events.BUYER_ENTERED_EMAIL, trackingOpts);
};

export const trackClickedNextPayment = ({
  userId,
  orderLink,
  fullName,
  email,
  address1,
  address2,
  city,
  state,
  zipcode,
  price,
}) => {
  const trackingOpts = {
    id: userId || 'noUserId',
    orderLink,
    fullName,
    email,
    address1,
    address2,
    city,
    state,
    zipcode,
    price,
  };
  identify(userId, trackingOpts);
  track(events.BUYER_CLICKED_NEXT_PAYMENT, trackingOpts);
};

export const trackSubmittedOrder = ({
  userId,
  orderLink,
  orderId,
  email,
  shippingPrice,
  price,
}) => {
  const trackingOpts = {
    id: userId || 'noUserId',
    email,
    orderLink,
    orderId,
    shippingPrice,
    price,
  };

  identify(userId, trackingOpts);
  track(events.BUYER_SUBMITTED_ORDER, trackingOpts);
};
