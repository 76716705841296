/*
 Link information data model factory method.
*/
export default function LinkData(item) {
  const data = {};
  const shipping = {};

  if (item.address && item.address.id) {
    shipping.id = item.address.id;
  }

  if (item.address && item.address.option) {
    shipping.option = item.address.option;
  }

  if (item.address && item.address.flatRate) {
    shipping.flatRate = item.address.flatRate;
  }

  if (item.address && item.address.rateOption) {
    shipping.rateOption = item.address.rateOption;
  }

  const linkItems = item.linkItems.items.map((linkItem) => ({
    id: linkItem.id,
    imageUrl: linkItem.imageUrl,
    itemName: linkItem.itemName,
    price: linkItem.price,
    quantity: linkItem.quantity,
  }));

  data.linkItems = linkItems;
  data.id = item.id;
  data.requireShipping = item.requireShipping;
  data.shipping = shipping;
  data.status = item.status;
  data.totalPrice = item.totalPrice;
  data.updatedAt = item.updatedAt;
  data.username = item.user.username;
  data.businessName = item.user.businessName;
  data.profilePictureUrl = item.user.profilePictureUrl;

  return data;
}
