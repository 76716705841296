import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#0061FE',
          '&.Mui-disabled': {
            backgroundColor: '#DADADA',
          },
        },
      },
    },
  },
});

export default theme;
