export const styles = {
  container: {
    maxWidth: 500,
    margin: '0 auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 32,
    paddingLeft: 32,
  },
  logo: {
    height: 45,
    marginTop: 5,
    marginBottom: 62,
  },
  checkIcon: {
    color: '#27AE60',
    height: 36,
    width: 36,
    marginBottom: 16,
  },
  infoContainer: {
    borderRadius: 16,
    backgroundColor: '#F2F2F2',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 18,
    marginBottom: 48,
  },
  itemImage: {
    height: 80,
    width: 80,
    borderRadius: 8,
    marginRight: 16,
    objectFit: 'cover',
  },
  divider: {
    borderTop: '1.2px dashed rgba(29, 27, 35, .08)',
    width: '100%',
    marginBottom: 20,
  },
  greyText: {
    color: '#9FA2AB',
    fontSize: 13,
  },
};

export default styles;
