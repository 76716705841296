import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Typography } from '@mui/material';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { LOCAL_URL } from '../../constants/config';
import styles from '../../pages/payment/payment.styles';

export default function CheckoutForm({ price, shippingPrice, email, orderId }) {
  const params = useParams();
  const { urlToken } = params;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${LOCAL_URL}/${urlToken}/confirmation/${email}/${shippingPrice}/${orderId}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      // eslint-disable-next-line no-console
      console.warn(error);
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PaymentElement id="payment-element" />
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}

      <Button
        style={styles.nextButton}
        onClick={handleSubmit}
        disabled={isLoading || !stripe || !elements}
        sx={{
          '&:hover': {
            bgcolor: 'rgb(1, 97, 254)',
          },
        }}
      >
        <Typography style={{ fontSize: 16 }}>
          {isLoading ? (
            <div className="spinner" id="spinner" />
          ) : (
            `Pay $${
              shippingPrice
                ? (price + shippingPrice)?.toFixed(2)
                : price?.toFixed(2)
            }`
          )}
        </Typography>
      </Button>
    </div>
  );
}
