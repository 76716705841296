import React, { useCallback, useState, useEffect } from 'react';
import { useParams, Routes, Route } from 'react-router-dom';
import Summary from '../summary/summary';
import Address from '../address/address';
import Payment from '../payment/payment';
import Confirmation from '../confirmation/confirmation';
import { getLinkData } from '../../database/queries';
import { LinkData } from '../../data-model';

const LIST_LINK_PAGE_SIZE = 9999;

const OrderForm = () => {
  const [linkData, setLinkData] = useState();
  const [price, setPrice] = useState();
  const [shippingPrice, setShippingPrice] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [requireShipping, setRequireShipping] = useState();
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [profileImage, setProfileImage] = useState();
  const [orderId, setOrderId] = useState();
  const [sellerEmail, setSellerEmail] = useState('');
  const [businessName, setBusinessName] = useState('');

  const params = useParams();
  const { urlToken } = params;

  const alertUser = useCallback((e) => {
    window.location.href = '/';
    e.preventDefault();
    e.returnValue = '';
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const result = await getLinkData(urlToken, LIST_LINK_PAGE_SIZE);
      if (
        result.data &&
        result.data.listLinks &&
        result.data.listLinks.items.length === 1
      ) {
        const itemResults = result.data.listLinks.items[0];
        if (itemResults.status === 'ACTIVE') {
          // TODO: move to constants
          setLinkData(LinkData(itemResults));
          setRequireShipping(itemResults.requireShipping);
          setPrice(Number.parseFloat(itemResults.totalPrice.toFixed(2)));
          setProfileImage(itemResults.user.profilePictureUrl);
          setSellerEmail(itemResults.user.username);

          if (itemResults.user.businessName) {
            setBusinessName(itemResults.user.businessName);
          }

          if (itemResults.address?.flatRate) {
            setShippingPrice(itemResults.address.flatRate);
          }
        } else {
          setLinkData({ status: itemResults.status });
        }
      } else {
        setLinkData('error');
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Summary
            price={price}
            shippingPrice={shippingPrice}
            linkData={linkData}
            profileImage={profileImage}
            businessName={businessName}
          />
        }
      />
      <Route
        path="/address"
        element={
          <Address
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            requireShipping={requireShipping}
            setEmail={setEmail}
            price={price}
            shippingPrice={shippingPrice}
            linkData={linkData}
            setShippingPrice={setShippingPrice}
            setClientSecret={setClientSecret}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            zipcode={zipcode}
            setZipcode={setZipcode}
            setOrderId={setOrderId}
          />
        }
      />
      <Route
        path="/payment"
        element={
          <Payment
            fullName={fullName}
            email={email}
            price={price}
            shippingPrice={shippingPrice}
            setShippingPrice={setShippingPrice}
            linkData={linkData}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            requireShipping={requireShipping}
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zipcode={zipcode}
            orderId={orderId}
            sellerEmail={sellerEmail}
          />
        }
      />
      <Route
        path="/confirmation/:email/:shippingPrice/:orderId/*"
        element={
          <Confirmation
            email={email}
            price={price}
            shippingPrice={shippingPrice}
            linkData={linkData}
            orderId={orderId}
          />
        }
      />
    </Routes>
  );
};

export default OrderForm;
