import amplitude from 'amplitude-js';
import { normalizeTrackingOptions } from './AnalyticsUtils';

const isInitialized = false;
const apiKey = 'b4b9f454aa7f209972ade3bae0165150'; // reminder to remove to .env file later

export const events = {
  BUYER_CLICKED_READY_TO_PAY: 'BUYER_CLICKED_READY_TO_PAY',
  BUYER_ENTERED_NAME: 'BUYER_ENTERED_NAME',
  BUYER_ENTERED_EMAIL: 'BUYER_ENTERED_EMAIL',
  BUYER_ENTERED_ADDRESS: 'BUYER_ENTERED_ADDRESS',
  BUYER_CLICKED_NEXT_PAYMENT: 'BUYER_CLICKED_NEXT_PAYMENT',
  BUYER_SUBMITTED_ORDER: 'BUYER_SUBMITTED_ORDER',
};

const canUseAmplitude = true;

export function initialize() {
  if (isInitialized || !canUseAmplitude) {
    return;
  }

  amplitude.getInstance().init(apiKey);
}

export function identify(id, options) {
  initialize();
  const properties = normalizeTrackingOptions(options);

  if (!canUseAmplitude) return;
  if (id) {
    amplitude.setUserId(id);
    if (properties) {
      amplitude.setUserProperties(properties);
    }
  } else {
    amplitude.clearUserProperties();
  }
}

export function track(event, options) {
  initialize();
  const properties = normalizeTrackingOptions(options);

  if (!canUseAmplitude) return;

  if (properties) {
    amplitude.logEvent(event, properties);
  } else {
    amplitude.logEvent(event);
  }
}
