import gql from 'graphql-tag';
import client from './client';

export const createOrder = async (linkId, input) => {
  const mutate = gql(`
    mutation MyMutation {
      createOrder(input: {
        email: "${input.email}", 
        fullName: "${input.name}", 
        status: ${input.status}, 
        linkOrdersId: "${linkId}", 
        shippingAddressFirst: "${input.address1}", 
        shippingAddressSecond: "${input.address2}", 
        shippingCity: "${input.city}", 
        shippingState: "${input.state}", 
        shippingCountry: "us", 
        shippingZipcode: "${input.zipcode}", 
        paidPrice: ${input.price}
      }) {
        id
        link {
          id
          url
        }
      }
    }
  `);

  return (
    client
      .mutate({ mutation: mutate })
      .then((data) => data)
      // @TODO: Properly handle error e.g. send to error tracking service
      // eslint-disable-next-line no-console
      .catch(console.error)
  );
};

export const getLinkData = async (orderId, limit) => {
  const query = gql(`
    query MyQuery {
      listLinks(filter: {url: {contains: "${orderId}"}}, limit: ${limit}) {
        items {
          id
          requireShipping
          totalPrice
          updatedAt
          status
          user {
            businessName
            profilePictureUrl
            username
          }
          linkItems {
            items {
              id
              imageUrl
              itemName
              price
              quantity
              updatedAt
            }
          }
          requireShipping
          address {
            id
            option
            rateOption
            flatRate
          }
        }
      }
    }
  `);

  return (
    client
      .query({ query })
      .then((data) => data)
      // @TODO: Properly handle error e.g. send to error tracking service
      // eslint-disable-next-line no-console
      .catch(console.error)
  );
};
