import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blueLogo from '../../assets/logo_blue.png';
import { trackSubmittedOrder } from '../../amplitude';
import { SERVER_URL } from '../../constants/config';
import styles from './confirmation.styles';

const Confirmation = ({ price, linkData }) => {
  const params = useParams();
  const { email } = params;
  const { orderId } = params;
  const { urlToken } = params;
  const shippingPrice = Number.parseFloat(params.shippingPrice);

  const IMAGE_STORAGE_URL =
    'https://plexi-app-images155849-dev.s3.amazonaws.com/public/';

  useEffect(() => {
    fetch(`${SERVER_URL}/api/confirm_payment`, {
      method: 'POST',
      headers: {
        'access-control-allow-origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order_id: orderId,
      }),
    }).then(() => {
      trackSubmittedOrder({
        orderId,
        orderLink: urlToken,
        email,
        shippingPrice,
        price,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // @FIXME: The image button here needs restructuring to be a11y compliant
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <div style={styles.container}>
      <img
        src={blueLogo}
        alt="logo"
        style={styles.logo}
        onClick={() => {
          window.location.href = 'https://www.plexipay.co/';
        }}
      />
      <CheckCircleIcon style={styles.checkIcon} />
      <Typography
        style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 16 }}
      >
        Order completed
      </Typography>
      <Typography
        style={{
          fontSize: 14,
          alignItems: 'center',
        }}
      >
        Confirmation email sent to
      </Typography>
      <Typography
        style={{
          fontWeight: 'bold',
          alignSelf: 'center',
          marginBottom: 48,
        }}
      >
        {email}
      </Typography>
      <div style={styles.infoContainer}>
        <Typography
          align="left"
          style={{ color: '#9FA2AB', fontSize: 13, marginBottom: 8 }}
        >
          Item
        </Typography>
        {linkData &&
          linkData.linkItems.map((item) => {
            return (
              <div key={item.id} style={styles.item}>
                {item.imageUrl != null && (
                  <img
                    src={IMAGE_STORAGE_URL + item.imageUrl}
                    alt="item"
                    style={styles.itemImage}
                  />
                )}
                <div style={styles.itemTextContainer}>
                  <Typography
                    align="left"
                    style={{ fontSize: 14, marginBottom: 16 }}
                  >
                    {item.itemName}
                    {item.quantity > 1 && ` x ${item.quantity}`}
                  </Typography>
                </div>
              </div>
            );
          })}
        <div style={styles.divider} />
        <Grid container rowSpacing={2}>
          <Grid item xs={6}>
            <Typography style={styles.greyText}>Item Prices</Typography>
          </Grid>
          <Grid item xs={6}>
            {price && (
              <Typography align="right" style={{ fontSize: 14 }}>
                {`$${price?.toFixed(2)}`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography display="inline" style={styles.greyText}>
              Shipping
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            {price && (
              <Typography display="inline" style={{ fontSize: 14 }}>
                {shippingPrice ? `$${shippingPrice?.toFixed(2)}` : '--'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography
              display="inline"
              style={{ fontWeight: 'bold', fontSize: 13 }}
            >
              Total
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            {price && (
              <Typography
                display="inline"
                style={{ fontWeight: 'bold', color: '#1251D4', fontSize: 14 }}
              >
                {shippingPrice
                  ? `$${(price + shippingPrice)?.toFixed(2)}`
                  : `$${price?.toFixed(2)}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Confirmation;
