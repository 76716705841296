const styles = {
  background: {
    background: 'linear-gradient(#DCDBF9 27%, #FFFEDA 52%, #FFFFFF 84%) 100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    marginTop: 135,
    justifyContent: 'center',
    display: 'flex',
  },
  body: {
    maxWidth: 450,
    paddingLeft: 24,
    paddingRight: 24,
  },
  profileImg: {
    height: 80,
    width: 80,
    position: 'absolute',
    top: 40,
    borderRadius: 90,
    border: '2px solid white',
    alignSelf: 'center',
    zIndex: 1,
  },
  profileImgBackground: {
    backgroundColor: '#1251D4',
    opacity: '.07',
    height: 90,
    width: 90,
    position: 'absolute',
    top: 37,
    borderRadius: 45,
    alignSelf: 'center',
  },
  itemImage: {
    height: 92,
    width: 92,
    objectFit: 'cover',
    marginLeft: 14,
    borderRadius: 8,
    display: 'flex',
  },
  itemContainer: {
    borderRadius: 16,
    height: 120,
    backgroundColor: 'white',
    alignItems: 'center',
    marginBottom: 8,
    boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
  },
  itemTextContainer: {
    flexGrow: 1,
    maxHeight: 100,
    marginLeft: 14,
    marginRight: 14,
  },
  logo: {
    width: 80,
  },
};

export default styles;
