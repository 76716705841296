export const LOCAL_URL =
  process.env.REACT_APP_MODE === 'DEVELOPMENT'
    ? 'http://localhost:3000'
    : 'https://orderform.plexipay.co';
export const SERVER_URL =
  process.env.REACT_APP_MODE === 'DEVELOPMENT'
    ? 'http://localhost:3001'
    : 'https://internal.plexipay.co';
export const STRIPE_KEY =
  process.env.REACT_APP_MODE === 'DEVELOPMENT'
    ? 'pk_test_51IJ7QdLj1jDJvlmwpNy4zv4OFo5OWttWA1GdtbuFhuA3iVFqm3rJ8uDiCbR3lpKymnLnnVkjabQx3ItgJ8eV5qHM00IH30CZm9'
    : 'pk_live_51IJ7QdLj1jDJvlmwOs3XrZaHpL4j1nzAArtqnZcKH1fTd4BEcmMDjde8isFqMFawHk0gRZsqHiy4qJb1D5C7Jx9g00tOUB0ZmW';
